import React, { useContext, useEffect } from "react";
import { useState, useRef } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { convertFileToBase64_Split } from "../../../utils/FileUtils";
import FeedbackService from "../../../services/FeedbackService";
import {
  MAX_FILE_SIZE_FEEDBACK,
  VALID_FILE_EXTENSION_FEEDBACK,
} from "../../../constants/const_values";
import { Attachment, Send } from "iconoir-react";
import { GetLoginUserDetails } from "../../../utils/Utils";
import "../css/Support.css";
import { SELECT_MenuProps } from "../../../constants/const_string";
import { File, Ticket, XLg } from "react-bootstrap-icons";
import { v4 } from "uuid";
import axios from "axios";
import DashboardServices from "../../../services/DashboardServices";
import { Skeleton, Zoom } from "@mui/material";
import SupportBotLogo from "../assets/images/SupportBotLogo.svg";
import {
  AttachEmail,
  CopyAllOutlined,
  FileUploadOutlined,
  SendSharp,
} from "@mui/icons-material";
import "../css/zoeyBot.css";
import TicketForm from "./TicketForm";
import SupportTicketView from "./SupportTicketView";
import { AddTabContext } from "../../dashboard/view/DashboardPage";
import { webApp } from "../../../constants/url_provider";
import { t } from "i18next";
import zhAIService from "../../../services/ZhAIService";
import { useTranslation } from "react-i18next";
import SecureIndexedDB from "../../../utils/IndexedDB";
import DOMPurify from "dompurify";
import Joyride from "react-joyride";
import { TourContext } from "../../../common/TourProvider";
// import SupportTicketView from "../../support/view/SupportTicketView";
// import { AddTabContext } from "./DashboardPage";

function Support({ setShowSupportRightPanel }) {
  const { i18n } = useTranslation();
  const userDetails = GetLoginUserDetails();
  let locale = i18n.language;

  const { steps, run, setRun } = useContext(TourContext);
  const [booksteps, setBookSteps] = useState([]);

  // if (
  //   userDetails.orgId === "ORG000000000319" ||
  //   userDetails.orgId === "ORG000000000309"
  // ) {
  //   locale = "es";
  // }
  const { addTab, getAllTabs } = useContext(AddTabContext);
  // const expendFeedbackRef = useRef(null);
  const hideChatIoc = useRef(null);
  const ffCAconvo = useRef(null);
  const inputFileRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("ffSupport");

  const [selectedModule, setSelectedModule] = useState("");
  const [comment, setComment] = useState("");
  const [uploadFileList, setUploadFileList] = useState([]);

  // const expendFeedback = async () => {
  //   expendFeedbackRef.current.classList.add("ffenter");
  //   expendFeedbackRef.current.classList.add("ffexpand");
  //   hideChatIoc.current.classList.add("dNone");
  //   ffCAconvo.current.classList.add("ffCAenter");
  // };

  // const collapsFeedback = async () => {
  //   expendFeedbackRef.current.classList.remove("ffenter");
  //   expendFeedbackRef.current.classList.remove("ffexpand");
  //   hideChatIoc.current.classList.remove("dNone");
  //   ffCAconvo.current.classList.remove("ffCAenter");
  // };

  const handleChangeRadio = (e) => {
    setSelectedRadio(e.target.value);
    setSelectedModule("");
    setComment("");
    setUploadFileList([]);
  };

  const handleChangeFileUpload = async (e) => {
    const files = e.target.files;
    let tmpList = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 1024 * 1024 * MAX_FILE_SIZE_FEEDBACK) {
        // toast.warning(
        //   `'${file.name}' Need To Be Less Then ${MAX_FILE_SIZE_FEEDBACK}MB`,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //   }
        // );
        toast.warning(
          t("support_file_size_warning", {
            fileName: file.name,
            maxFileSize: MAX_FILE_SIZE_FEEDBACK,
          }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else if (
        !VALID_FILE_EXTENSION_FEEDBACK.includes(file.name.split(".").pop())
      ) {
        // toast.warning(`Invalid File Type Of '${file.name}'`, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        toast.warning(t("support_invalid_file_type", { fileName: file.name }), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        tmpList.push({
          fileName: file.name,
          fileData: await convertFileToBase64_Split(file),
          fileDisplayName: "",
        });
      }
    }

    setUploadFileList(tmpList);
    inputFileRef.current.value = null;
  };

  const handleSubmitFeedback = () => {
    if (comment === "") {
      toast.error(t("please_enter_your_comment"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const reqDto = {
        bugsSuggestion: `${selectedRadio === "ffSuggestion" ? `S` : ``}${
          selectedRadio === "ffBugs" ? `B` : ``
        }`,
        module: selectedModule,
        comments: comment,
        documents: uploadFileList,
      };
      // console.log(reqDto);
      setLoading(true);
      FeedbackService.betaFeedback(userDetails.userId, reqDto).then(
        (response) => {
          if (response.data) {
            if (response.data.returnValue === "1") {
              toast.success(t("feedback_success"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              setSelectedModule("");
              setComment("");
              setUploadFileList([]);
            } else {
              toast.error(t("something_went_wrong"), {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            setLoading(false);
          }
        }
      );
    }
  };

  const [activeDrags, setActiveDrags] = useState(0);
  const handleDrag = {
    onStart: () => setActiveDrags(activeDrags + 1),
    onStop: () => setActiveDrags(activeDrags - 1),
  };

  const [showZoeyBotIoc, setShowZoeyBotIoc] = useState(true);
  const [showZoey, setShowZoey] = useState(false);
  const [loadingReply, setLoadingReply] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [messagesCount, setMessagesCount] = React.useState(0);
  const [profileImage, setProfileImage] = useState(null);
  const inputRef = useRef(null);
  const sessionId = v4();
  const handleCloseZoey = () => {
    setShowZoey(false);
    setShowZoeyBotIoc(true);
  };
  const handleShowZoey = () => {
    setShowZoey(true);
    setShowZoeyBotIoc(false);
    setMessagesCount(messagesCount + 1);
  };

  const sendMessage = async (message) => {
    const query = inputRef.current.value?.trim();
    inputRef.current.value = "";
    if (!query) {
      toast.error(t("please_ask_a_question"));
      inputRef.current.focus();
      // inputRef.current.style.border = "2px solid red";
      return;
    }
    setMessagesCount(messagesCount + 1);

    // let chatDataSession = JSON.parse( SecureIndexedDB.getItem("zoyel-chat-data"));
    // let sessionId = null;
    // if (chatDataSession) {
    //   sessionId = chatDataSession.sessionId;
    // } else {
    //   sessionId = v4();
    // }

    let reqDto = {
      user_message: query,
      session_id: sessionId,
      locale: locale,
    };

    setConversation((prevList) => [
      ...prevList,
      { id: prevList.length + 1, message: reqDto.user_message, type: "user" },
    ]);

    setLoadingReply(true);

    // let apiUrl =
    //   webApp === "zoyel.health"
    //     ? "https://aichatbot.zoyel.health/chat"
    //     : "https://aichatbot.ahlan.work/chat";

    // const response = await axios.post(apiUrl, reqDto);
    const response = await zhAIService.chatBot(reqDto);
    console.log("zhAIService.chatBot" + response);
    setLoadingReply(false);

    setConversation((prevConversation) => [
      ...prevConversation,
      {
        id: prevConversation.length + 1,
        message: response.data.response,
        images: response.data.images,
        type: "bot",
      },
    ]);

    // setConversation(prevConversation => [
    //   ...prevConversation,
    //   ...newConversations
    // ]);

    // if (chatDataSession) {
    //   let chatHistory = chatDataSession.chatHistory;
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: reqDto.user_message,
    //     type: "user",
    //   });
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: response.data.response,
    //     type: "bot",
    //   });
    //   let chatData = {
    //     sessionId: reqDto.session_id,
    //     chatHistory: chatHistory,
    //   };
    //   SecureIndexedDB.setItem("zoyel-chat-data", JSON.stringify(chatData));
    //   setConversation(chatHistory);
    // } else {
    //   let chatHistory = [];
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: reqDto.user_message,
    //     type: "user",
    //   });
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: response.data.response,
    //     type: "bot",
    //   });
    //   let chatData = {
    //     sessionId: reqDto.session_id,
    //     chatHistory: chatHistory,
    //   };
    //   SecureIndexedDB.setItem("zoyel-chat-data", JSON.stringify(chatData));
    //   setConversation(chatHistory);
    // }
  };

  useEffect(() => {
    SecureIndexedDB.setItem("zoyel-chat-data", null);
    // const chatDataSession = JSON.parse( SecureIndexedDB.getItem("zoyel-chat-data"));
    // console.log("useEffect chat", chatDataSession);
    // if (chatDataSession) {
    //   console.log("useEffect chat", chatDataSession.chatHistory);
    //   setConversation(chatDataSession.chatHistory);
    // }
    DashboardServices.getUserProfile(userDetails.userId).then((response) => {
      if (response.data && response.data.profileImageFileDataLink) {
        setProfileImage(
          "data:image/png;base64," + response.data.profileImageFileDataLink
        );
      } else {
        setProfileImage(
          "https://ahlan-s3.s3.me-south-1.amazonaws.com/images/no-profile.png"
        );
      }
    });
  }, []);

  const parseResponse = (content) => {
    let contentTemp = DOMPurify.sanitize(content.replaceAll("\n", "<br/>"));
    return <div dangerouslySetInnerHTML={{ __html: contentTemp }} />;
  };

  const chatEndRef = React.useRef(null);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      console.log(
        "scroll",
        chatEndRef.current.scrollHeight,
        chatEndRef.current.scrollTop
      );
      chatEndRef.current.scrollTop = chatEndRef.current.scrollHeight;
    }
  };

  React.useEffect(() => {
    console.log("scroll", messagesCount);
    scrollToBottom();
  }, [messagesCount]);

  const [refreshState, setRefreshState] = useState(0);

  const createTicket = async () => {
    setLoadingReply(true);
    console.log("Create software ticket");
    setMessagesCount(messagesCount + 1);

    // let chatDataSession = JSON.parse( SecureIndexedDB.getItem("zoyel-chat-data"));
    // let sessionId = null;
    // if (chatDataSession) {
    //   sessionId = chatDataSession.sessionId;
    // } else {
    //   sessionId = v4();
    // }
    // let sessionId = v4();
    let reqDto = {
      user_message: t("create_a_ticket"),
      session_id: sessionId,
    };

    setConversation((prevList) => [
      ...prevList,
      { id: prevList.length + 1, message: reqDto.user_message, type: "user" },
    ]);

    const autoReply = "Software";
    console.log("autoReply", autoReply);
    setRefreshState(refreshState + 1);
    setLoadingReply(false);
    setConversation((prevConversation) => [
      ...prevConversation,
      {
        id: prevConversation.length + 1,
        message: autoReply,
        type: "bot",
        autoReply: true,
        openForm: true,
      },
    ]);
    // if (chatDataSession) {
    //   let chatHistory = chatDataSession.chatHistory;
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: reqDto.user_message,
    //     type: "user",
    //   });
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: autoReply,
    //     type: "bot",
    //     autoReply: true,
    //     openForm: true,
    //   });
    //   let chatData = {
    //     sessionId: reqDto.session_id,
    //     chatHistory: chatHistory,
    //   };
    //   SecureIndexedDB.setItem("zoyel-chat-data", JSON.stringify(chatData));
    //   setConversation(chatHistory);
    // } else {
    //   let chatHistory = [];
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: reqDto.user_message,
    //     type: "user",
    //   });
    //   chatHistory.push({
    //     id: chatHistory.length + 1,
    //     message: autoReply,
    //     type: "bot",
    //     autoReply: true,
    //     openForm: true,
    //   });
    //   let chatData = {
    //     sessionId: reqDto.session_id,
    //     chatHistory: chatHistory,
    //   };
    //   SecureIndexedDB.setItem("zoyel-chat-data", JSON.stringify(chatData));
    //   setConversation(chatHistory);
    // }
  };

  // const [createdTicketDetails, setCreatedTicketDetails] = useState(null);

  useEffect(() => {
    setBookSteps([
      {
        target: ".supportTicketCreateStepOne",
        content: "Here is where you can close the support panel.",
        disableBeacon: true,
      },
      {
        target: ".supportTicketCreateStepTwo",
        content: "This area contains the conversation or feedback forms.",
        disableBeacon: true,
      },
      {
        target: ".supportTicketCreateStepThree",
        content: "You can interact with the bot here to get support.",
        disableBeacon: true,
      },
      {
        target: ".supportTicketCreateStepFour",
        content: "You can create a support ticket after chatting with the bot.",
        disableBeacon: true,
      },
      {
        target: ".supportTicketCreateStepFive",
        content: "Attach any screenshots or files related to bugs.",
        disableBeacon: true,
      },
      {
        target: ".supportTicketCreateStepSix",
        content: "Send your feedback here after entering the details.",
        disableBeacon: true,
      },
      {
        target: ".supportTicketCreateStepSeven",
        content: "Type your message here to interact with the bot.",
        disableBeacon: true,
      },
    ]);
  }, []);

  return (
    <>
      <Joyride
        steps={booksteps}
        run={run["rightSideCreateTicket"] === true}
        continuous={true}
        showSkipButton={true}
        scrollToFirstStep={true}
        scrollTo={true}
        scrollOffset={200}
        styles={{
          buttonClose: {
            display: "none",
          },
        }}
      />
      <div className="supportContainer">
        <div className="supportHeader supportTicketCreateStepOne">
          <span className="supportTitle">{t("support")}</span>
          <div className="supportClose">
            <IconButton onClick={() => setShowSupportRightPanel(false)}>
              <XLg />
            </IconButton>
          </div>
        </div>

        <div className="supportBody supportTicketCreateStepTwo">
          {loading && t("please_wait")}
          {/* <div className="supportOptions">
          <p className="supportBodyTitle">
            If you need support, have suggestions, or encounter any bugs, kindly
            let us know
          </p>

          <FormControl>
            <RadioGroup row defaultValue="ffSupport">
              <FormControlLabel
                value="ffSupport"
                control={<Radio />}
                label="Support"
                checked={selectedRadio === "ffSupport"}
                onChange={handleChangeRadio}
              />
              <FormControlLabel
                value="ffSuggestion"
                control={<Radio />}
                label="Suggestion"
                checked={selectedRadio === "ffSuggestion"}
                onChange={handleChangeRadio}
              />
              <FormControlLabel
                value="ffBugs"
                control={<Radio />}
                label="Report a Bug"
                checked={selectedRadio === "ffBugs"}
                onChange={handleChangeRadio}
              />
            </RadioGroup>
          </FormControl>
        </div> */}

          <div className="showFFcontainGrp">
            {selectedRadio !== "" && (
              <>
                <div
                  className="showSuggetionContainer"
                  id="showSuggetionContainer"
                >
                  {selectedRadio === "ffSupport" && (
                    <>
                      <div className="botFlotingContainer supportTicketCreateStepThree">
                        <div className="botContContain">
                          <div
                            className="botContContainDetails"
                            ref={chatEndRef}
                          >
                            <div className="botMsg" key={-1}>
                              <div className="bMsg msgFrmBot">
                                <div className="msgImg">
                                  <img src={SupportBotLogo} alt="bot_Img" />
                                </div>
                                <div className="msgBody">
                                  <div className="msgUserNm">Zoey</div>
                                  <div className="msgBodyTxt">
                                    {/* {parseResponse(
                                    "Hi, I am Zoey, your support assistant, you can either chat with me or create a ticket"
                                  )} */}

                                    {parseResponse(t("support_message"))}
                                    {"zoyel.one" !== webApp && (
                                      <Button
                                        className="createTicketBtn supportTicketCreateStepFour"
                                        endIcon={<SendSharp />}
                                        onClick={() => createTicket()}
                                      >
                                        {t("create_a_ticket")}
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {conversation.map((convDto) => {
                              console.log("convDto-->>>", convDto);
                              if (convDto.type === "user") {
                                return (
                                  <div className="botMsg" key={convDto.id}>
                                    <div className="bMsg msgFrmUser">
                                      <div className="msgImg">
                                        <img
                                          src={profileImage}
                                          alt="user_Img"
                                        />
                                      </div>
                                      <div className="msgBody">
                                        <div className="msgUserNm">
                                          {t("you")}
                                        </div>
                                        <div className="msgBodyTxt">
                                          <p>{convDto.message}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="botMsg" key={convDto.id}>
                                    <div className="bMsg msgFrmBot">
                                      <div className="msgImg">
                                        <img
                                          src={SupportBotLogo}
                                          alt="user_Img"
                                        />
                                      </div>
                                      <div className="msgBody">
                                        <div className="msgUserNm">Zoey</div>
                                        <div className="msgBodyTxt">
                                          {convDto.autoReply &&
                                            convDto.openForm && (
                                              <TicketForm
                                                onTicketCreated={(
                                                  ticketData
                                                ) => {
                                                  console.log(
                                                    "onTicketCreated",
                                                    ticketData
                                                  );

                                                  setConversation(
                                                    (prevConversation) =>
                                                      prevConversation.map(
                                                        (item) =>
                                                          item.id === convDto.id
                                                            ? {
                                                                ...item,
                                                                openForm: false,
                                                                openTicketCreatedForm: true,
                                                                createdTicketDetails:
                                                                  ticketData,
                                                              }
                                                            : item
                                                      )
                                                  );
                                                }}
                                              />
                                            )}

                                          {convDto.autoReply &&
                                            convDto.openTicketCreatedForm && (
                                              <>
                                                {" "}
                                                <p>
                                                  {t(
                                                    "your_ticket_has_been_created_please_find_the_ticket_id_below"
                                                  )}
                                                  <br></br>
                                                  {
                                                    convDto.createdTicketDetails
                                                      .supportDisplayId
                                                  }
                                                </p>
                                                <Button
                                                  className="createTicketBtn"
                                                  endIcon={<SendSharp />}
                                                  onClick={() => {
                                                    const tabKey = v4();
                                                    console.log("key", tabKey);
                                                    addTab({
                                                      key: tabKey,
                                                      title:
                                                        t("support_dashboard"),
                                                      content: (
                                                        <SupportTicketView
                                                          selectedTicket={
                                                            convDto
                                                              .createdTicketDetails
                                                              .supportDisplayId
                                                          }
                                                        />
                                                      ),
                                                      isDraggable: true,
                                                      tabIndex:
                                                        getAllTabs().length,
                                                      driveKey: tabKey,
                                                    });
                                                  }}
                                                >
                                                  {t(
                                                    "view_created_ticket_details"
                                                  )}
                                                </Button>
                                                <Button
                                                  className="createTicketBtn"
                                                  endIcon={<SendSharp />}
                                                  onClick={() => createTicket()}
                                                >
                                                  {t("create_another_ticket")}
                                                </Button>
                                              </>
                                            )}

                                          {!convDto.autoReply && (
                                            <>
                                              <p>
                                                {parseResponse(convDto.message)}
                                              </p>
                                              <div>
                                                {convDto.images &&
                                                  convDto.images.map(
                                                    (image) => {
                                                      return (
                                                        <>
                                                          <img
                                                            className="imageBotReply"
                                                            src={`data:image/png;base64,${image}`}
                                                            alt="botReplyImg"
                                                          />
                                                        </>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                              <div className="zoeyActions">
                                                <Tooltip
                                                  title={t("copy")}
                                                  placement="top-end"
                                                  arrow
                                                  TransitionComponent={Zoom}
                                                >
                                                  <CopyAllOutlined
                                                    onClick={() => {
                                                      toast.success(
                                                        t("response_copied")
                                                      );
                                                      navigator.clipboard.writeText(
                                                        convDto.message.replaceAll(
                                                          "\n",
                                                          "<br/>"
                                                        )
                                                      );
                                                    }}
                                                  />
                                                </Tooltip>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                            {loadingReply && (
                              <div className="botMsg" key={0}>
                                <div className="bMsg msgFrmBot">
                                  <div className="msgImg">
                                    <img src={SupportBotLogo} alt="bot_Img" />
                                  </div>
                                  <div className="msgBody">
                                    <div className="msgUserNm">Zoey</div>
                                    <div className="msgBodyTxt">
                                      <Skeleton
                                        variant="text"
                                        sx={{
                                          fontSize: "0.9rem",
                                          bgcolor: "#ff9900",
                                          width: "31vw",
                                          lineHeight: "20px",
                                        }}
                                      />
                                      <Skeleton
                                        variant="text"
                                        sx={{
                                          fontSize: "1rem",
                                          bgcolor: "#ff9900",
                                          width: "31vw",
                                          lineHeight: "20px",
                                        }}
                                      />
                                      <Skeleton
                                        variant="text"
                                        sx={{
                                          fontSize: "1rem",
                                          bgcolor: "#ff9900",
                                          width: "14vw",
                                          lineHeight: "20px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {(selectedRadio === "ffSuggestion" ||
                    selectedRadio === "ffBugs") && (
                    <>
                      <FormControl
                        fullWidth
                        size="small"
                        className="coustomDDFF"
                      >
                        <InputLabel id="fedbSugdwon">
                          {t("select_section")}
                        </InputLabel>
                        <Select
                          labelId="fedbSugdwon"
                          id="fedbSugdwon"
                          label={t("select_section")}
                          value={selectedModule}
                          onChange={(e) => setSelectedModule(e.target.value)}
                          MenuProps={SELECT_MenuProps}
                        >
                          <MenuItem value="">{t("select")}</MenuItem>
                          <MenuItem value="Scheduler">
                            {t("scheduler")}
                          </MenuItem>
                          <MenuItem value="Meetings">{t("meetings")}</MenuItem>
                          <MenuItem value="Chat">{t("chat")}</MenuItem>
                          <MenuItem value="Drive">{t("drive")}</MenuItem>
                          <MenuItem value="Assistant">
                            {t("assistant")}
                          </MenuItem>
                          <MenuItem value="Sheets">{t("sheets")}</MenuItem>
                          <MenuItem value="Word Processor">
                            {t("word_processor")}
                          </MenuItem>
                          <MenuItem value="Slides">{t("slides")}</MenuItem>
                          <MenuItem value="Profile">{t("profile")}</MenuItem>

                          {userDetails.orgId !== "ORG000000000000" &&
                            userDetails.userType !== "EXTERNAL" && (
                              <MenuItem value="Admin">{t("admin")}</MenuItem>
                            )}

                          {userDetails.orgId !== "ORG000000000000" &&
                            userDetails.userType !== "EXTERNAL" && (
                              <MenuItem value="Analysis">
                                {t("analytics")}
                              </MenuItem>
                            )}

                          {userDetails.orgId !== "ORG000000000000" &&
                            userDetails.userType !== "EXTERNAL" && (
                              <MenuItem value="Task">{t("task")}</MenuItem>
                            )}

                          {userDetails.orgId === "ORG000000000000" && (
                            <MenuItem value="Add Contacts">
                              {t("add_contacts")}
                            </MenuItem>
                          )}

                          {userDetails.orgId === "ORG000000000000" && (
                            <MenuItem value="Create Organization">
                              {t("create_organization")}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>

                      <TextareaAutosize
                        className="coustomTextArea"
                        minRows={6}
                        maxRows={6}
                        placeholder={`${
                          selectedRadio === "ffSuggestion"
                            ? t("please_enter_here_your_valuable_suggestion")
                            : ``
                        } ${
                          selectedRadio === "ffBugs"
                            ? t(
                                "please_enter_brief_description_of_bug_that_you_facing"
                              )
                            : ``
                        }`}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </>
                  )}
                  {selectedRadio === "ffBugs" && (
                    <>
                      <div
                        className="showBugsContainer supportTicketCreateStepFive"
                        id="showBugsContainer"
                      >
                        <Tooltip
                          title={
                            uploadFileList.length > 0 ? (
                              <ul>
                                {uploadFileList.map((file, index) => (
                                  <li key={index}>{file.fileName}</li>
                                ))}
                              </ul>
                            ) : (
                              `${t(
                                "maximum_file_size_allowed"
                              )} ${MAX_FILE_SIZE_FEEDBACK}MB, ${t(
                                "extensions_allowed"
                              )} ${VALID_FILE_EXTENSION_FEEDBACK.map(
                                (item) => ` ${item}`
                              )}`
                            )
                          }
                          placement="top"
                        >
                          <Button
                            className="attachSrcBtn"
                            variant="contained"
                            startIcon={<Attachment />}
                            onClick={() => inputFileRef.current.click()}
                          >
                            {t("attach_screenshot")}
                            <input
                              type="file"
                              hidden
                              multiple
                              ref={inputFileRef}
                              onChange={handleChangeFileUpload}
                            />
                          </Button>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        {/* <div className="supportFooter">
        <Button
          className="sendFeedBtn"
          variant="contained"
          endIcon={<Send />}
          onClick={handleSubmitFeedback}
          disabled={loading}
        >
          Send Feedback
        </Button>
      </div> */}
        {selectedRadio !== "ffSupport" && (
          <div className="supportFooter">
            <div className="formBtnElement">
              <Button
                className="dfultPrimaryBtn supportTicketCreateStepSix"
                onClick={handleSubmitFeedback}
                variant="contained"
                endIcon={<Send />}
              >
                {t("send_feedback")}
              </Button>
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => {
                  setShowSupportRightPanel(false);
                }}
              >
                {t("cancel")}
              </Button>
              {/* <Button
                className="dfultPrimaryBtn"
                onClick={() => finalSubmit()}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <>{t("submit")}</>
                )}
              </Button>
              {!loading && (
                <Button
                  className="dfultDarkSecondaryBtn"
                  onClick={() => handelCloseAddPeople()}
                >
                  {t("cancel")}
                </Button>
              )} */}
            </div>
          </div>
        )}
        {selectedRadio === "ffSupport" && (
          <div className="botContFooter">
            <div className="botInputGrp">
              <input
                ref={inputRef}
                type="text"
                className="botComntInput supportTicketCreateStepSeven"
                id="botComntInput"
                placeholder={t("how_may_i_help_you")}
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    sendMessage();
                  }
                }}
              />
              <IconButton
                className="botComtSndBtn"
                onClick={() => {
                  sendMessage();
                }}
              >
                <Send />
              </IconButton>
              {/* <IconButton
                className="botComtSndBtn"
                onClick={() => {
                  // sendMessage();
                }}
              >
                <FileUploadOutlined />
              </IconButton> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Support;
